<template>
    <div>
      <base-page title="Farmers" :breadcrumbs="breadcrumbs">
        <template #content>
          <farmer-list/>
        </template>
      </base-page>
    </div>
  </template>
  
  <script>
  export default {
    components: {
      'farmer-list': () => import('@/views/farmer/FarmerList.vue'),
    },
    data() {
      return {
        breadcrumbs: [{ text: "Farmers", disabled: true }],
      };
    },
  };
  </script>
  